import React, { useState } from 'react';
import { graphql } from 'gatsby';

//@ts-ignore
import * as styles from './statement-block-with-icon-option.module.css';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ArrowLgButton from '../Global/ArrowLgButton';
import {
    getColor,
    getMediaData,
    getMultiChoice,
    getStatement,
} from '../Global/DataUtils/index';
import {
    KontentRadioMultiChoice,
    KontentStringValue,
    BrandColor,
    CTAConfiguration,
    GeneralImages,
    Statement,
    KontentRichText,
    Statements,
} from '../../../types/KontentProps';
import { RichTextEl } from '../Global/RichText';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import PlainTextElement from '../Global/Headlines';
import loadable from '@loadable/component';

const ReactPlayer = loadable(() => import('react-player'));

const motion = {
    div: createDomMotionComponent('div'),
};

type BgDisplayOptions = 'image' | 'color_only';
type HideOnMobile = 'yes' | 'no';
type PagePositionOptions =
    | 'sits_under_preceding_component'
    | 'lays_on_top_of_preceding_component';
type VideoPositionOptions =
    | 'directly_under_headline'
    | 'under_description_and_cta';

interface Props extends CTAConfiguration, GeneralImages {
    background_display_option: KontentRadioMultiChoice<BgDisplayOptions>;
    component_anchor_name: KontentStringValue;
    content_panel__content_panel_color: BrandColor;
    content_panel__content_panel_font_color: BrandColor;
    description_copy: KontentRichText;
    headline?: KontentStringValue;
    page_positioning: KontentRadioMultiChoice<PagePositionOptions>;
    statements: Statements;
    component_page_background_color: BrandColor;
    hide_icon_statements_on_mobile: KontentRadioMultiChoice<HideOnMobile>;
    video_link?: KontentStringValue;
    video_link_position?: KontentRadioMultiChoice<VideoPositionOptions>;
    autoplay_video?: KontentRadioMultiChoice<HideOnMobile>;
    control_bottom_margin?: KontentRadioMultiChoice<string>;
    desktop_bottom_margin?: KontentStringValue;
    mobile_bottom_margin?: KontentStringValue;
}

const StatementBlockWithIconOption: React.FC<Props> = ({
    background_display_option,
    component_anchor_name,
    content_panel__content_panel_color,
    content_panel__content_panel_font_color,
    description_copy,
    headline,
    page_positioning,
    statements,
    cta_configuration__cta_label,
    cta_configuration__cta_location,
    cta_configuration__cta_location_anchor_point,
    cta_configuration__cta_window_action,
    general_large___small_image_selection__large,
    general_large___small_image_selection__small,
    component_page_background_color,
    hide_icon_statements_on_mobile,
    video_link,
    video_link_position,
    autoplay_video,
    control_bottom_margin,
    desktop_bottom_margin,
    mobile_bottom_margin,
    ...props
}) => {
    const orange = '#f06623';
    const autoPlayOn = autoplay_video?.value[0]?.codename === 'yes' || false;
    const controlMargin = control_bottom_margin?.value?.[0]?.codename === 'yes';
    const [currentStatement, setCurrentStatement] = useState(0);
    const numStatements = statements?.value?.length;
    const breakpoints = useBreakpoint();
    const marginStyles = controlMargin
        ? {
              marginBottom: breakpoints.md
                  ? `${desktop_bottom_margin?.value || 0}px`
                  : `${mobile_bottom_margin?.value || 0}px`,
          }
        : {};
    const islarge = !breakpoints.l;
    const Video =
        video_link.value != '' ? (
            <div className={styles.videoContainer}>
                <ReactPlayer
                    url={video_link.value}
                    style={{
                        margin: '0 auto',
                    }}
                    width={islarge ? '90%' : '640px'}
                    height={islarge ? '40vw' : '360px'}
                    playing={autoPlayOn}
                    controls
                />
            </div>
        ) : (
            <></>
        );

    const contentStyles = {
        color: getColor(content_panel__content_panel_font_color),
        backgroundColor: getColor(content_panel__content_panel_color),
        marginTop:
            getMultiChoice(page_positioning) ===
            'lays_on_top_of_preceding_component'
                ? '-8rem'
                : undefined,
    };
    const pageBackgroundColor =
        getColor(component_page_background_color) || '#f5f4f2';
    const largeImage = getMediaData(
        general_large___small_image_selection__large
    );
    const smallImage = getMediaData(
        general_large___small_image_selection__small
    );

    const handleClickNext = () => {
        setCurrentStatement(currentStatement + 1);
    };

    const handleClickPrev = () => {
        if (currentStatement === 0) {
            setCurrentStatement(numStatements - 1);
        } else {
            setCurrentStatement(currentStatement - 1);
        }
    };
    const renderStatement = (statement: Statement, idx: number) => {
        const statementData = getStatement(statement);

        if (breakpoints.md || idx === currentStatement % numStatements)
            return (
                <AnimatePresence
                    key={`${idx}${statementData.displayTitle?.value}`}
                >
                    <motion.div
                        key={idx}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.statement}
                    >
                        <div className={styles.iconTitleWrapper}>
                            <div className={styles.iconWrapper}>
                                <img
                                    src={statementData?.icon?.url}
                                    width="90"
                                    height="90"
                                />
                            </div>
                            <h4>{statementData.displayTitle?.value}</h4>
                            {!breakpoints.md && (
                                <ArrowLgButton
                                    className={styles.prev}
                                    strokeColor={orange}
                                    direction="left"
                                    handleClick={handleClickPrev}
                                />
                            )}
                            {!breakpoints.md && (
                                <ArrowLgButton
                                    className={styles.next}
                                    strokeColor={orange}
                                    direction="right"
                                    handleClick={handleClickNext}
                                />
                            )}
                        </div>

                        {(breakpoints.md ||
                            hide_icon_statements_on_mobile?.value[0]
                                ?.codename === 'no') &&
                            statementData?.richDescription?.value && (
                                <RichTextEl
                                    richText={statementData.richDescription}
                                />
                            )}
                    </motion.div>
                </AnimatePresence>
            );
    };

    return (
        <section
            className={styles.wrapper}
            style={{ backgroundColor: pageBackgroundColor, ...marginStyles }}
            id={component_anchor_name.value || undefined}
        >
            <div className={styles.content} style={contentStyles}>
                {headline?.value && (
                    <h2>
                        <PlainTextElement text={headline} />
                    </h2>
                )}

                {getMultiChoice(video_link_position) ===
                    'directly_under_headline' && Video}
                {description_copy?.value != '<p><br></p>' && (
                    <div className={styles.description}>
                        <RichTextEl richText={description_copy} />
                    </div>
                )}
                <div className={styles.statements}>
                    {statements?.value?.map((statement, idx) =>
                        renderStatement(statement, idx)
                    )}
                </div>
                {cta_configuration__cta_location &&
                    cta_configuration__cta_label?.value && (
                        <div className={styles.ctaWrapper}>
                            <CtaLink
                                href={BuildLinkHref(
                                    cta_configuration__cta_location,
                                    cta_configuration__cta_location_anchor_point
                                )}
                                target={
                                    getMultiChoice(
                                        cta_configuration__cta_window_action
                                    ) === 'same_window'
                                        ? undefined
                                        : '_blank'
                                }
                                color={
                                    getColor(
                                        content_panel__content_panel_font_color
                                    ) || undefined
                                }
                            >
                                {cta_configuration__cta_label.value}
                            </CtaLink>
                        </div>
                    )}
                {getMultiChoice(video_link_position) ===
                    'under_description_and_cta' && Video}
                {!breakpoints.md && smallImage?.url && (
                    <img
                        src={smallImage?.url}
                        alt={smallImage?.description || smallImage?.name}
                        className={styles.ghostedImage}
                    />
                )}
                {breakpoints.md && largeImage?.url && (
                    <img
                        src={largeImage?.url}
                        alt={largeImage?.description || largeImage?.name}
                        className={styles.ghostedImage}
                    />
                )}
            </div>
        </section>
    );
};

export default StatementBlockWithIconOption;

export const fragmentStatementBlockWithIconOption = graphql`
    fragment StatementBlockWithIconOption on kontent_item_component___statement_block_with_icon_option {
        id
        elements {
            background_display_option {
                value {
                    codename
                }
            }
            component_anchor_name {
                value
            }
            component_page_background_color {
                ...kontentColor
            }
            content_panel__content_panel_color {
                ...kontentColor
            }
            content_panel__content_panel_font_color {
                ...kontentColor
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            description_copy {
                ...kontentRichText
            }
            general_large___small_image_selection__large {
                ...kontentImage
            }
            general_large___small_image_selection__small {
                ...kontentImage
            }
            headline {
                value
            }
            page_positioning {
                value {
                    codename
                }
            }
            hide_icon_statements_on_mobile {
                value {
                    codename
                }
            }
            video_link {
                value
            }
            video_link_position {
                value {
                    codename
                }
            }
            autoplay_video {
                value {
                    codename
                }
            }
            control_bottom_margin {
                value {
                    name
                    codename
                }
            }
            mobile_bottom_margin {
                value
            }
            desktop_bottom_margin {
                value
            }
            statements {
                value {
                    ... on kontent_item_content_item___statement_with_icon {
                        elements {
                            display_title {
                                value
                            }
                            icon {
                                value {
                                    ... on kontent_item_media___icon {
                                        elements {
                                            ...kontentIcon
                                        }
                                    }
                                }
                            }
                            rich_description {
                                ...kontentRichText
                            }
                        }
                    }
                }
            }
        }
    }
`;
